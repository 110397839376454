<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin solivita-form py-4 px-4">
      <div class="text-center mb-5 mb-lg-20">
        <!-- <img :src="siteLogo()" alt="Logo" style="max-width:300px;" /> -->
      </div>

      <div role="alert" v-if="errorMessage && errorMessage.length">
        <v-alert border="right" colored-border type="error" elevation="2">
          {{ errorMessage }}
        </v-alert>
      </div>

      <div v-if="step == 1">
        <div class="text-center mb-5 mb-lg-20">
          <p
            class="font-weight-semi-bold mt-4"
            style="color: black; font-size:20px; font-weight: bold;"
          >
            Resident user registration
          </p>
          <p style="color: black; font-size:20px;">
            Your email may already exists as your login for syncscape.<br />
            Please enter your email below.
          </p>
        </div>
        <v-text-field
          background-color="white"
          outlined
          dense
          color="#F06835"
          label="Email Address"
          v-model="emailToCheck"
        >
        </v-text-field>

        <div class="form-group d-flex flex-column align-items-center">
          <v-btn
            type="submit"
            color="#F06835"
            dark
            class="mb-2 mt-2"
            style="min-width: 188px"
            :loading="isSubmitting"
            large
            @click="checkEmail()"
          >
            <span style="font-size:large">Check my email</span>
          </v-btn>
        </div>
      </div>
      <div v-if="step == 2">
        <div class="text-center mb-5 mb-lg-20">
          <p
            class="font-weight-semi-bold mt-4"
            style="color: black; font-size:20px; font-weight: bold;"
          >
            Resident user registration
          </p>
          <p style="color: black; font-size:20px;">
            Great! We found your email: {{ emailToCheck }}. <br />
            Please click the "reset my password" button and <br />
            an email will be sent to your email address. <br />
            Simply, follow the instructions to reset your password.
          </p>
        </div>

        <div class="form-group d-flex flex-column align-items-center">
          <v-btn
            type="submit"
            color="#F06835"
            dark
            class="mb-2 mt-2"
            style="min-width: 188px"
            :loading="isSubmitting"
            large
            @click="sendPasswordResetEmail()"
          >
            <span style="font-size:large">Reset My Password</span>
          </v-btn>
        </div>
      </div>
      <div v-if="step == 3">
        <div class="text-center mb-5 mb-lg-20">
          <p
            class="font-weight-semi-bold mt-4"
            style="color: black; font-size:20px; font-weight: bold;"
          >
            Resident user registration
          </p>
          <p style="color: black; font-size:20px;">
            Reset password email has been sent to {{ emailToCheck }}. <br />
            Please check your mailbox and <br />
            follow the instructions to reset your password.
          </p>
        </div>

        <div class="form-group d-flex flex-column align-items-center">
          <v-btn
            type="submit"
            color="#F06835"
            dark
            class="mb-2 mt-2"
            style="min-width: 188px"
            :loading="isSubmitting"
            large
            @click="goToLogin()"
          >
            <span style="font-size:large">Go To Login</span>
          </v-btn>
        </div>
      </div>
      <div v-if="step == 4">
        <div class="text-center mb-5 mb-lg-20">
          <!-- <h3 class="font-size-h1">Login</h3> -->
          <p
            class="font-weight-semi-bold mt-4"
            style="color: black; font-size:20px; font-weight: bold;"
          >
            Resident user registration
          </p>
          <p style="color: black; font-size:20px;">
            Your email does not exist as a registered user of syncscape. <br />
            Please fill out the information below to complete your
            registration<br />
            to access syncscape for submitting service requests. <br />
            If you need assistance, contact support@yardnique.com.
          </p>
          <p
            class="font-weight-semi-bold mt-4"
            style="color: black; font-size:20px;"
          >
            User self-registration is currently restricted to the <br />
            communities in the dropdown below. <br />
            Please first select the community in which you are associated<br />
            before you continue.
          </p>
          <v-select
            background-color="white"
            outlined
            dense
            color="#F06835"
            label="Select Community"
            required
            :items="communities"
            item-text="name"
            item-value="name"
            v-model="community"
          ></v-select>
          <p
            class="font-weight-semi-bold mt-4"
            style="color: black; font-size:20px;"
          >
            Your email address will be your syncscape login name
          </p>
          <!-- <p>Logging you in...</p> -->
        </div>

        <!--begin::Form-->
        <v-form class="form" @submit.stop.prevent="onSubmit">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  background-color="white"
                  outlined
                  dense
                  color="#F06835"
                  label="First Name"
                  required
                  v-model="$v.form.firstName.$model"
                  :error-messages="firstNameErrors"
                  @input="$v.form.firstName.$touch()"
                  @blur="$v.form.firstName.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  background-color="white"
                  outlined
                  dense
                  color="#F06835"
                  label="Last Name"
                  required
                  v-model="$v.form.lastName.$model"
                  :error-messages="lastNameErrors"
                  @input="$v.form.lastName.$touch()"
                  @blur="$v.form.lastName.$touch()"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-select
              background-color="white"
              outlined
              dense
              color="#F06835"
              label="Select your Neighborhood"
              required
              :items="properties"
              item-text="name"
              item-value="id"
              v-model="$v.form.property.$model"
              :error-messages="propertyErrors"
              @input="$v.form.property.$touch()"
              @blur="$v.form.property.$touch()"
            ></v-select>

            <v-text-field
              background-color="white"
              outlined
              dense
              color="#F06835"
              label="Property Address (Street Address Only)"
              required
              v-model="$v.form.address.$model"
              :error-messages="addressErrors"
              @input="$v.form.address.$touch()"
              @blur="$v.form.address.$touch()"
            ></v-text-field>

            <v-text-field
              background-color="white"
              outlined
              dense
              color="#F06835"
              label="Email Address"
              required
              v-model="$v.form.username.$model"
              :error-messages="usernameErrors"
              @input="$v.form.username.$touch()"
              @blur="$v.form.username.$touch()"
            ></v-text-field>

            <v-text-field
              background-color="white"
              outlined
              dense
              color="#F06835"
              label="Cell Phone (required for text updates)"
              required
              v-model="$v.form.phone.$model"
              :error-messages="phoneErrors"
              @input="$v.form.phone.$touch()"
              @blur="$v.form.phone.$touch()"
            ></v-text-field>
            <p style="font-size: 16px;">
              Your password must be a minimum 6 characters and contain a capital
              letter
            </p>
            <v-text-field
              background-color="white"
              outlined
              dense
              color="#F06835"
              label="Enter your password"
              :type="showPassword ? 'text' : 'password'"
              @click:append.prevent="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              required
              v-model="$v.form.password.$model"
              :error-messages="passwordErrors"
              @input="$v.form.password.$touch()"
              @blur="$v.form.password.$touch()"
            ></v-text-field>

            <v-text-field
              background-color="white"
              outlined
              dense
              color="#F06835"
              label="Confirm your password"
              :type="showPassword ? 'text' : 'password'"
              @click:append.prevent="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :extra-rules="[
                form.password === form.confirmPassword ||
                  'Passwords do not match'
              ]"
              required
              v-model="$v.form.confirmPassword.$model"
              :error-messages="confirmPasswordErrors"
              @input="$v.form.confirmPassword.$touch()"
              @blur="$v.form.confirmPassword.$touch()"
            ></v-text-field>
          </v-container>

          <div class="form-group d-flex flex-column align-items-center">
            <v-btn
              type="submit"
              color="#F06835"
              dark
              class="mb-2 mt-2"
              style="min-width: 188px"
              :loading="isSubmitting"
              large
            >
              <span style="font-size:large">Submit</span>
            </v-btn>
          </div>
        </v-form>
        <!--end::Form-->
      </div>
    </div>
    <!--end::Signin-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT, REFRESH } from "@/core/store/auth.module";
import {
  POST_DATA_2,
  API_CUSTOMERS,
  API_USERS,
  API_USER_EXISTS
} from "@/core/store/crud.module";
import { FORGOT_PASSWORD } from "@/core/store/forgotPassword.module";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import Mgr from "@/core/services/security.service";

export default {
  mixins: [validationMixin],
  name: "ResidentRegistration",
  data() {
    return {
      mgr: new Mgr(),
      isSubmitting: false,
      showPassword: false,
      step: 1,
      emailToCheck: "",
      errorMessage: "",
      community: "",
      form: {
        firstName: null,
        lastName: null,
        property: null,
        address: null,
        phone: "",
        username: null,
        password: "",
        confirmPassword: ""
      },
      communities: [
        { name: "Compass Pointe" },
        { name: "Del Webb Grande Dunes" },
        { name: "Del Webb Lakewood Ranch" },
        { name: "Solivita" },
        { name: "Sun City Peachtree" }
      ],

      propertiesData: [
        // Solivita
        { id: 717529, name: "Solivita - Alta Vista" },
        { id: 717531, name: "Solivita - Bella Viana" },
        { id: 717532, name: "Solivita - Bella Vista" },
        { id: 717533, name: "Solivita - Candlewood" },
        { id: 717534, name: "Solivita - Capri" },
        { id: 717535, name: "Solivita - Courtyards" },
        { id: 717536, name: "Solivita - Flora Vista" },
        { id: 717537, name: "Solivita - Lago Vista" },
        { id: 717538, name: "Solivita - Mira Vista" },
        { id: 717539, name: "Solivita - Portofino Estates" },
        { id: 717540, name: "Solivita - Rainbow Lakes" },
        { id: 717541, name: "Solivita - Terra Vista" },
        { id: 717542, name: "Solivita - Treviso" },
        { id: 717543, name: "Solivita - Valencia" },
        { id: 717544, name: "Solivita - Varese" },
        { id: 717545, name: "Solivita - Venezia" },
        { id: 717546, name: "Solivita - Verona" },
        { id: 717547, name: "Solivita - Versilia" },
        { id: 717548, name: "Solivita - Vestrella" },
        { id: 717549, name: "Solivita - Viareggio" },
        { id: 717550, name: "Solivita - Villa Verla" },
        { id: 717551, name: "Solivita - Vittoria" },
        { id: 717552, name: "Solivita - Vizcava" },
        { id: 717553, name: "Solivita - Volare" },
        // Sun City
        { id: 715990, name: "Sun City Pod 1A" },
        { id: 715993, name: "Sun City Pod 1B" },
        { id: 715994, name: "Sun City Pod 2" },
        { id: 715995, name: "Sun City Pod 3" },
        { id: 715996, name: "Sun City Pod 6" },
        { id: 715997, name: "Sun City Pod 7" },
        { id: 715999, name: "Sun City Pod 8" },
        { id: 716001, name: "Sun City Pod 9" },
        { id: 716000, name: "Sun City Pod 10" },
        { id: 716002, name: "Sun City Pod 11" },
        { id: 716003, name: "Sun City Pod 12" },
        { id: 716004, name: "Sun City Pod 13/14" },
        { id: 716005, name: "Sun City Pod 15" },
        { id: 716006, name: "Sun City Pod 16" },
        { id: 716007, name: "Sun City Pod 17" },
        { id: 716009, name: "Sun City Pod 21" },
        { id: 716008, name: "Sun City Pod 22" },
        { id: 719325, name: "Sun City Pod 23" },
        { id: 716010, name: "Sun City Pod 28" },
        { id: 716011, name: "Sun City Pod 29" },
        { id: 716012, name: "Sun City Pod 30" },
        { id: 716013, name: "Sun City Pod 31" },
        { id: 716014, name: "Sun City Pod 33" },
        { id: 716015, name: "Sun City Pod 34" },
        { id: 716016, name: "Sun City Pod 35" },
        { id: 716017, name: "Sun City Pod 36" },
        { id: 716020, name: "Sun City Pod 37A" },
        { id: 716021, name: "Sun City Pod 37B" },
        { id: 716022, name: "Sun City Pod 37C" },
        { id: 716019, name: "Sun City Pod 38" },
        { id: 716018, name: "Sun City Pod 39" },
        // Compass Pointe
        { id: 710401, name: "Compass Pointe - Islands" },
        { id: 711214, name: "Compass Pointe - Cabanas" },
        { id: 711215, name: "Compass Pointe - Cottages" },
        { id: 711216, name: "Compass Pointe - Reserve Town Homes" },
        { id: 711217, name: "Compass Pointe - Reserve Patio Homes" },
        { id: 711218, name: "Compass Pointe - Heron Park Town Homes" },
        { id: 711219, name: "Compass Pointe - Heron Park Single Family Homes" },
        { id: 711220, name: "Compass Pointe - Eagle Terrace" },
        { id: 711221, name: "Compass Pointe - Greenway Landing" },
        { id: 711222, name: "Compass Pointe - Pointe Club Cabanas" },
        { id: 711223, name: "Compass Pointe - Pointe Village" },
        { id: 711224, name: "Compass Pointe - Pointe Club Villas" },
        // Del Webb Grande Dunes
        { id: 710510, name: "Del Webb Grande Dunes" },
        // { id: 719232, name: "Del Webb Grande Dunes - Phase 1" },
        // { id: 719233, name: "Del Webb Grande Dunes - Phase 2" },
        // { id: 719234, name: "Del Webb Grande Dunes - Phase 3" },
        // { id: 719235, name: "Del Webb Grande Dunes - Phase 4" },
        // { id: 719236, name: "Del Webb Grande Dunes - Phase 5" },
        // { id: 719237, name: "Del Webb Grande Dunes - Phase 6" },
        // { id: 719238, name: "Del Webb Grande Dunes - Phase 7" },
        // { id: 719239, name: "Del Webb Grande Dunes - Phase 8" },
        // { id: 719240, name: "Del Webb Grande Dunes - Phase 9" },
        // { id: 719241, name: "Del Webb Grande Dunes - Phase 12" },
        // Del Webb Lakewood Ranch
        { id: 718860, name: "Del Webb Lakewood Ranch Area 1" },
        { id: 718861, name: "Del Webb Lakewood Ranch Area 2" },
        { id: 718862, name: "Del Webb Lakewood Ranch Area 3" },
        { id: 718863, name: "Del Webb Lakewood Ranch Area 4" }
      ]
    };
  },
  validations: {
    emailToCheck: {
      email
    },
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      property: {
        required
      },
      address: {
        required
      },
      phone: {
        required
      },
      username: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: {
        required,
        minLength: minLength(3)
      }
    }
  },
  async created() {
    // window.confirm("login:load");
  },
  async mounted() {
    // clear existing errors
    await this.$store.dispatch(LOGOUT, this.$route.query);
    // await this.redirectToPortal();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapState({
      authErrors: state => state.auth.errors,
      crudErrors: state => state.crud.errors,
      isAuthenticated: state => state.auth.isAuthenticated
    }),
    properties() {
      let filterValue = "_no_data_";
      if (this.community == "Compass Pointe") {
        filterValue = "Compass Pointe";
      } else if (this.community == "Del Webb Grande Dunes") {
        filterValue = "Del Webb Grande Dunes";
      } else if (this.community == "Del Webb Lakewood Ranch") {
        filterValue = "Del Webb Lakewood Ranch";
      } else if (this.community == "Solivita") {
        filterValue = "Solivita";
      } else if (this.community == "Sun City Peachtree") {
        filterValue = "Sun City";
      }

      return this.propertiesData.filter(el => el.name.includes(filterValue));
    },

    firstNameErrors() {
      const errors = [];
      if (!this.$v.form.firstName.$dirty) return errors;
      !this.$v.form.firstName.required && errors.push("First Name is required");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.form.lastName.$dirty) return errors;
      !this.$v.form.lastName.required && errors.push("Last Name is required");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;
      !this.$v.form.address.required && errors.push("Address is required");
      return errors;
    },
    communityErrors() {
      const errors = [];
      if (!this.$v.form.community.$dirty) return errors;
      !this.$v.form.community.required && errors.push("Community is required");
      return errors;
    },
    propertyErrors() {
      const errors = [];
      if (!this.$v.form.property.$dirty) return errors;
      !this.$v.form.property.required &&
        errors.push("Neighborhood is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      !this.$v.form.phone.required && errors.push("Cell Phone is required");
      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.form.username.$dirty) return errors;
      !this.$v.form.username.email && errors.push("Must be valid e-mail");
      !this.$v.form.username.required && errors.push("Email is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.minLength &&
        errors.push("Must be at least 6 characters long");
      !this.$v.form.password.required && errors.push("Password is required");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.form.confirmPassword.$dirty) return errors;
      !this.$v.form.confirmPassword.minLength &&
        errors.push("Must be at least 6 characters long");
      !this.$v.form.confirmPassword.required &&
        errors.push("Confirm Password is required");
      !this.$v.form.password.$model !== !this.$v.form.confirmPassword.$model &&
        errors.push("Passwords don't match");
      return errors;
    }
  },
  methods: {
    async sendPasswordResetEmail() {
      this.errorMessage = "";

      const { success, error } = await this.$store.dispatch(
        FORGOT_PASSWORD,
        this.emailToCheck
      );

      if (success) {
        this.step = 3;
      } else {
        this.$toast.error(error.message ?? "Something went wrong.");
      }

      if (this.errors) {
        if (Array.isArray(this.errors))
          this.errorMessage = this.errors
            .map(({ message }) => message)
            .join("; ");
        else this.errorMessage = this.errors;
      }
    },
    async goToLogin() {
      await this.$router.push({ name: "login" });
    },
    async checkEmail() {
      const response = await this.$store.dispatch(POST_DATA_2, {
        listName: `${API_USER_EXISTS}`,
        data: { email: this.emailToCheck }
      });

      if (!response) {
        this.errorMessage = "Something went wrong. Cannot check email.";
        return;
      }
      if (!response.success) {
        this.errorMessage = response.data;
        return;
      }

      if (response.data) {
        this.step = 2;
      } else {
        this.form.username = this.emailToCheck;
        this.step = 4;
      }
    },
    siteLogo() {
      return process.env.BASE_URL + "media/bg/SolivitaLogo_RegPage.png";
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async redirectToPortal() {
      let self = this;
      try {
        if (!this.isAuthenticated) {
          return;
        }
        let user = await self.mgr.getUser();

        if (user == null || !user.role) {
          await this.$store.dispatch(REFRESH);
          user = await self.mgr.getUser();
        }
        if (user != null && user.role) {
          const customerViewRoles = [
            "Property Manager",
            "Board Member",
            "Resident",
            "Tenant/Resident",
            "Tenant/Resident - Neighborhood",
            "Landscape Committee"
          ];

          if (customerViewRoles.indexOf(user.role) > -1) {
            window.location.href = "/#/customerView/dashboard";
          } else {
            if (user.role == "Crew Leader") {
              window.location.href = "/#/create-orange-report";
            } else window.location.href = "/#/customers";
          }
        }
      } catch (ex) {
        window.confirm("login:catch" + ex);
        await self.mgr.signOut();
      }
    },

    async addUser() {
      this.newUser = {};
      this.newUser.userType = "Customer";
      this.newUser.userRoles = ["Tenant/Resident"];
      this.newUser.email = this.$v.form.username.$model;
      this.newUser.userName = this.newUser.email;
      this.newUser.password = this.$v.form.password.$model;
      this.newUser.phone = this.$v.form.phone.$model;
      this.newUser.address = this.$v.form.address.$model;
      this.newUser.firstName = this.$v.form.firstName.$model;
      this.newUser.lastName = this.$v.form.lastName.$model;

      const parsedCustomerId = parseInt(this.$v.form.property.$model);
      if (isNaN(parsedCustomerId)) {
        this.errorMessage = "Please, choose Neighborhood.";

        return;
      }
      this.newUser.communities = [parsedCustomerId];

      let self = this;

      const response = await this.$store.dispatch(POST_DATA_2, {
        listName: `${API_USERS}`,
        data: this.newUser
      });

      // console.log("create user response", response);
      if (!response) {
        this.errorMessage =
          "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";

        if (this.crudErrors) this.errorMessage = this.crudErrors;
        return;
      }

      if (!response.success) {
        this.errorMessage = response?.data;
        return;
      }

      let user = response.data;

      self.communitiesToProcess = self.newUser.communities.map(i => {
        return { id: i, processed: false };
      });

      self.userProcessed = false;

      const addResponse = await self.$store.dispatch(POST_DATA_2, {
        listName: `${API_CUSTOMERS}/${parsedCustomerId}/user/${user.identityId}`
      });

      // console.log("add property", addResponse);

      if (!addResponse) {
        this.errorMessage =
          "Something went wrong. Cannot add user to property.";
        return;
      }

      if (!addResponse.success) {
        this.errorMessage = this.addResponse.data;
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      await this.$store.dispatch(LOGIN, {
        username,
        password
      });

      if (this.authErrors.errors) {
        if (Array.isArray(this.authErrors.errors))
          this.errorMessage = this.authErrors.errors
            .map(({ message }) => message)
            .join("; ");
        else this.errorMessage = this.authErrors.errors;
      } else if (this.authErrors) {
        this.errorMessage = this.authErrors;
      }

      if (this.isAuthenticated) {
        //await this.$router.push({ name: "Customers" });
        await this.redirectToPortal();
      }
    },

    // async addUser() {
    //   this.newUser = {};
    //   this.newUser.userType = "Customer";
    //   this.newUser.userRoles = ["Tenant/Resident"];
    //   this.newUser.email = this.$v.form.username.$model;
    //   this.newUser.userName = this.newUser.email;
    //   this.newUser.password = this.$v.form.password.$model;
    //   this.newUser.phone = this.$v.form.phone.$model;
    //   this.newUser.address = this.$v.form.address.$model;
    //   this.newUser.firstName = this.$v.form.firstName.$model;
    //   this.newUser.lastName = this.$v.form.lastName.$model;

    //   const parsedCustomerId = parseInt(this.$v.form.property.$model);
    //   if (isNaN(parsedCustomerId)) {
    //     self.$snackbar.showMessage({
    //       content: "Please, choose Neighborhood.",
    //       color: "red"
    //     });
    //     return;
    //   }
    //   this.newUser.communities = [parsedCustomerId];

    //   let self = this;

    //   this.$store
    //     .dispatch(POST_DATA, {
    //       listName: `${API_USERS}`,
    //       data: this.newUser
    //     })
    //     .then(response => {
    //       // console.log("create user resposne", response);
    //       if (response && response.status >= 200 && response.status <= 204) {
    //         if (response.data.error) {
    //           if (
    //             response.data.error ===
    //             "Cannot create user, email already is in use."
    //           )
    //             self.snackbarContent =
    //               "Cannot create user, email already is in use.";
    //           else
    //             self.snackbarContent =
    //               "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
    //           self.snackbarColor = "red";
    //         } else {
    //           self.snackbarContent = "User has been created";

    //           let user = response.data;

    //           self.communitiesToProcess = self.newUser.communities.map(i => {
    //             return { id: i, processed: false };
    //           });

    //           self.userProcessed = false;

    //           self.$store
    //             .dispatch(POST_DATA, {
    //               listName: `${API_CUSTOMERS}/${parsedCustomerId}/user/${user.identityId}`
    //             })
    //             .then(addResponse => {
    //               if (addResponse.status >= 200 && addResponse.status <= 204) {
    //                 self.snackbarColor = "green";

    //                 const username = this.$v.form.username.$model;
    //                 const password = this.$v.form.password.$model;

    //                 this.$store
    //                   .dispatch(LOGIN, { username, password })
    //                   .then(() => {
    //                     if (this.loginErrors.errors) {
    //                       if (Array.isArray(this.loginErrors.errors))
    //                         this.errorMessage = this.loginErrors.errors
    //                           .map(({ message }) => message)
    //                           .join("; ");
    //                       else this.errorMessage = this.loginErrors.errors;
    //                     } else if (this.loginErrors) {
    //                       this.errorMessage = this.loginErrors;
    //                     }

    //                     if (this.isAuthenticated) {
    //                       if (this.$route.query.redirect) {
    //                         this.$router.push(this.$route.query.redirect);
    //                       } else {
    //                         //await this.$router.push({ name: "Customers" });
    //                         this.redirectToPortal();
    //                       }
    //                     }
    //                   });
    //               }
    //             });
    //         }
    //       } else if (response) {
    //         this.errorMessage = response?.data;
    //       } else {
    //         // console.log("create user error", this.createUserErrors);
    //         this.errorMessage =
    //           "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
    //       }
    //     })
    //     .catch(res => {
    //       // console.log(res);
    //       this.errorMessage =
    //         "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
    //       if (res)
    //         this.errorMessage =
    //           "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
    //     });
    // },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.isSubmitting = true;
      this.errorMessage = "";

      await this.addUser();

      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss" scoped>
// .password-reset {
//   font-weight: 500;
//   color: black;
//   &:hover {
//     color: #ab2f75;
//   }
// }

// p,
// label {
//   color: white;
// }

// input[type="text"] {
//   background-color: white;
// }

.solivita-form {
  background-color: rgba(255, 255, 255, 0.3);
}
</style>
